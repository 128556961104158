<template>
    <div v-if="authUserPermission['sap-connection-view']">
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <div class="card mb-4">
                <div class="card-header mt--4 mb--4">
                    <div class="row">
                        <div class="col">
                            <h3 class="mb-0">{{ tt('sap_connection') }}</h3>
                        </div>
                        <div class="col-6">
                            <base-input input-classes="form-control-sm text-center border-radius-20" v-model="table.search" :placeholder="tt('search_sap_connection')" v-on:keyup="filter"/>
                        </div>
                        <div class="col text-right">
                            <base-button size="sm" type="default" @click="create">{{ tt('add_new') }}</base-button>
                        </div>
                    </div>
                </div>
                <div>
                    <el-table height="350px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data" v-if="!onLoad">
                        <el-table-column :label="tt('connection')" :prop="tt('sap_connection')" min-width="180px" sortable>
                            <template v-slot="{row}">
                                {{row.connection}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('url')" :prop="tt('url')" min-width="170px" sortable>
                            <template v-slot="{row}">
                                {{row.url}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('username')" :prop="tt('username')" min-width="170px" sortable>
                            <template v-slot="{row}">
                                {{row.username}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('password')" :prop="tt('password')" min-width="170px" sortable>
                            <template v-slot="{row}">
                                {{row.password}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('default')" :prop="tt('default')" min-width="170px" sortable>
                            <template v-slot="{row}">
                                <label class="badge badge-primary" v-if="row.default == '1'">DEFAULT</label>
                                <label class="badge badge-danger" v-else="row.default == '0'">NOT DEFAULT</label>
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('created_by')" :prop="tt('created_by')" min-width="170px" sortable>
                            <template v-slot="{row}">
                                {{row.created_by}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('created_at')" :prop="tt('created_at')" min-width="170px" sortable>
                            <template v-slot="{row}">
                                {{row.created_at}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="action" width="100">
                            <template v-slot="{row}">
                                <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true">
                                    <span class="btn btn-sm btn-icon-only text-light">
                                        <i class="fas fa-ellipsis-v mt-2"></i>
                                    </span>
                                    <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                        <el-dropdown-item :command="{action:'edit', data:row.id}">{{ tt('edit') }}</el-dropdown-item>
                                        <el-dropdown-item :command="{action:'remove', data:row.id}">{{ tt('delete') }}</el-dropdown-item>                                        
                                        <el-dropdown-item :command="{action:'default', data:row.id}">{{ tt('default') }}</el-dropdown-item>                                        
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </el-table-column>
                    </el-table>
                <page-loading v-else/>
                </div>                 
                <div class="card-footer pb-0 ">   
                    <span class="float-left">
                        <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                    </span>                             
                    <span class="float-right">
                        {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                    </span>
                </div>
            </div>
        </div>
        <validation-observer v-slot="{invalid}">
            <modal :show.sync="form.show">
                <template slot="header">
                    <h5 class="modal-title">{{form.title}}</h5>
                </template>
                <div>
                    <label class="form-control-label">{{ tt('connection') }} <span class="text-danger">*</span></label>
                    <base-input :name="tt('connection')" :placeholder="tt('connection')" v-model="sapConnection.connection" rules="required"></base-input>

                    <label class="form-control-label">{{ tt('url') }} <span class="text-danger">*</span></label>
                    <base-input :name="tt('url')" :placeholder="tt('url')" v-model="sapConnection.url" rules="required"></base-input>

                    <label class="form-control-label">{{ tt('username') }} <span class="text-danger">*</span></label>
                    <base-input :name="tt('username')" :placeholder="tt('username')" v-model="sapConnection.username" rules="required"></base-input>

                    <label class="form-control-label">{{ tt('password') }} <span class="text-danger">*</span></label>
                    <base-input :name="tt('password')" :placeholder="tt('password')" v-model="sapConnection.password" rules="required"></base-input>
                </div>
                <template slot="footer">
                    <base-button type="secondary" @click="form.show = false">{{ tt('close') }}</base-button>
                    <base-button type="primary" v-on:click="save" :disabled="btnSave.onLoading || invalid">
                        <span v-if="btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                        <span v-else>
                            <span v-if="form.add">{{ tt('add') }}</span>
                            <span v-else>{{ tt('edit') }}</span>
                        </span>
                    </base-button>
                </template>
            </modal>
        </validation-observer>
    </div>
    <noaccess v-else/>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import sapConnection from '@/services/sap/sapConnection.service';

    export default {        
        data() {
            return {  
                onLoad: true,      
                loadTimeout: null,  
                btnSave: {
                    onLoading: false
                },             
                form: {
                    add: true,
                    title: this.tt('add_sap_connection'),
                    show: false
                }, 
                table: {
                    search: '',                    
                    total: 0,
                    data: [],
                    page: {
                        current_page: 1,
                        per_page: 100,
                    },
                },        
                sapConnection: {},  
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
        },
        methods: {
            get() { 
                let context = this;               
                Api(context, sapConnection.get(this.table.page.current_page, {search: this.table.search})).onSuccess(function(response) {    
                    context.table.total = response.data.data.data.total;
                    context.table.data  = response.data.data.data.data;  
                    context.table.page  = response.data.data.data;  
                    // console.log(response.data.data.data.data[0]['id']);
                    context.onLoad      = false;      
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.table.data = [];
                        context.table.total = 0;
                        context.onLoad = false;
                    }
                })
                .call()
            },
            filter() {
                this.onLoad = true;
                this.table.page.current_page = 1;
                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.edit(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    case 'default':
                            this.default(command.data);
                        break;
                    default:
                        break;
                }
            },
            create() {
                this.form.add = true;
                this.form.title = this.tt('add_sap_connection');
                this.form.show = true;
                this.sapConnection = {};
            },
            edit(id) {
                this.errorMessage = null;
                let context = this;               
                Api(context, sapConnection.show(id)).onSuccess(function(response) {
                    context.sapConnection = response.data.data;
                    context.form.add = false;
                    context.form.title = context.tt('edit_sap_connection');
                    context.form.show = true;                          
                })
                .call()        
            },
            save() {                      
                this.btnSave.onLoading = true;
                let api = null;
                let context = this;
                if (this.form.add) {
                    api = Api(context, sapConnection.create(this.sapConnection));
                } else {{
                    api = Api(context, sapConnection.update(this.sapConnection.id, this.sapConnection));
                }}
                api.onSuccess(function(response) {
                    context.get();
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.form.show = false;
                }).onError(function(error) {                    
                    context.errorMessage = error.response.data;
                }).onFinish(function() {
                    context.btnSave.onLoading = false;   
                })
                .call();
            },
            remove(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        Api(context, sapConnection.delete(id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                            context.get();
                        }).call();
                    }
                })
            },            
            default(id) {
                this.confirmDialog(this.tt('confirm_default')).then((result) => {
                    let context = this;
                    if (result.value) {
                        Api(context, sapConnection.change_default(id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                            context.get();
                        }).call();
                    }
                })
            },
            changePage(page) {
                let context = this;
                context.onLoad = true;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
        }   
    };
</script>
